import React, { forwardRef, MutableRefObject } from 'react';
import { InputGroup, InputGroupAddon } from 'reactstrap';
import { LabelItem } from './LabelItem';
import { Exist } from '../Exist';
import { InputItemDropdown } from './InputItemDropdown';
import styled from 'styled-components';
import theme from '../../assets/css/theme';
import { FaSearch } from 'react-icons/fa';
import { InputStyled } from './InputStyled';
import { IDictionary } from '../../modules/types';
import { FormTooltip, InputTooltip } from './InputTooltip';

interface IWrapper {
  isInline: boolean;
  margin?: string;
}

const Wrapper = styled.div<IWrapper>`
  display: ${(props) => (props.isInline ? 'flex' : 'block')};
  margin: ${(props) => props.margin || '0 0 10px 0'};
`;

const SearchIcon = styled(FaSearch)`
  position: absolute;
  top: 10px;
  left: 10px;
`;

const InputGroupWrapper = styled.div`
  position: relative;
`;

const ErrorWrapper = styled.div`
  color: #ff003a;
  font-size: 12px;
  line-height: 18px;
  margin-top: 5px;
`;

interface IDropdownConfig {
  buttonLabel: string;
  options: IDictionary<string>[];
  callback: (val: string) => void;
}

interface IProps {
  value: string | number;
  placeholder?: string;
  withTooltip?: boolean;
  label?: string | JSX.Element;
  id?: string;
  isInline?: boolean;
  tooltipPlacement?: string;
  tooltipIconPlacement?: string;
  tooltipText?: string;
  changeCallback: (value: string) => void;
  onBlurCallback?: (e: React.SyntheticEvent) => void;
  onFocusCallback?: (e: React.SyntheticEvent) => void;
  onFocus?: (e: React.BaseSyntheticEvent) => void;
  margin?: string;
  isSearch?: boolean;
  formTheme?: string;
  type?: string;
  ref?: MutableRefObject<any>;
  pattern?: RegExp;
  onKeyDown?: (e: React.SyntheticEvent) => void;
  disabled?: boolean;
  step?: string;
  min?: number;
  max?: number;
  dropdownConfig?: IDropdownConfig;
  rightAddonElement?: any;
  errorText?: string;
  required?: boolean;
  keyDownHandler?: (e: React.SyntheticEvent) => void;
  style?: React.CSSProperties;
}

// tslint:disable-next-line:cyclomatic-complexity
export const InputItem = forwardRef((props: IProps, ref) => {
  const {
    value,
    placeholder = '',
    withTooltip,
    label,
    id,
    isInline = false,
    tooltipPlacement = 'right',
    tooltipText,
    changeCallback,
    onBlurCallback,
    onFocusCallback,
    margin,
    isSearch,
    formTheme,
    pattern,
    dropdownConfig,
    rightAddonElement,
    tooltipIconPlacement,
    errorText,
    keyDownHandler,
    required,
    ...rest
  } = props;

  const changeHandler = (e: React.BaseSyntheticEvent) => {
    if (e.currentTarget.value && pattern && !pattern.test(e.currentTarget.value)) {
      e.preventDefault();
    } else {
      changeCallback(e.currentTarget.value);
    }
  };
  const tooltipID = `${id}_tooltip`;
  const tooltipProps = {
    isShow: Boolean(withTooltip),
    tooltipID,
    formTheme,
    tooltipPlacement,
    tooltipText,
    radius: rightAddonElement ? '0px' : null
  };
  const tooltip = <InputTooltip {...tooltipProps} />;
  const onBlurHandler = (e: React.SyntheticEvent) => onBlurCallback && onBlurCallback(e);
  const onFocusHandler = (e: React.SyntheticEvent) => onFocusCallback && onFocusCallback(e);

  return (
    <Wrapper isInline={isInline} margin={margin}>
      <Exist when={Boolean(label)}>
        <LabelItem htmlFor={id} isSmall isBold>
          <>
            {label}
            {required && '*'}
            <Exist when={Boolean(tooltipIconPlacement === 'label')}>
              <FormTooltip {...tooltipProps} />
            </Exist>
          </>
        </LabelItem>
      </Exist>
      <InputGroupWrapper className="input-item-group">
        <InputGroup>
          <InputStyled
            onChange={changeHandler}
            onBlur={onBlurHandler}
            onFocus={onFocusHandler}
            value={value}
            placeholder={placeholder}
            id={id}
            isSearch={isSearch}
            formTheme={formTheme}
            innerRef={ref}
            onKeyDown={keyDownHandler}
            style={props.style}
            {...rest}
          />
          {dropdownConfig ? (
            <InputItemDropdown {...dropdownConfig} disabled={rest.disabled} />
          ) : null}
          {tooltipIconPlacement !== 'label' ? tooltip : null}
          <Exist when={Boolean(rightAddonElement)}>
            <InputGroupAddon addonType="append" id={tooltipID}>
              {rightAddonElement}
            </InputGroupAddon>
          </Exist>
        </InputGroup>
        <Exist when={Boolean(isSearch)}>
          <SearchIcon color={theme.commonColors.second} />
        </Exist>
      </InputGroupWrapper>
      <Exist when={Boolean(errorText)}>
        <ErrorWrapper>{errorText}</ErrorWrapper>
      </Exist>
    </Wrapper>
  );
});

export default InputItem;
