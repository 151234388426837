import { createSelector } from 'reselect';
import { IStoreState } from '../types';
import { ifImplementsKeys } from '../../helpers/objectFunctions';
import { BILLING_ADD_FIELDS } from '../../modules/constants/subscriptions';
import { IAdressInterface, IPaymentProblem } from '../../modules/types/paymentInfo';

export const getPaymentInfo = (state: IStoreState) => state.paymentInfo;
export const getPaymentsWithProblem = (state: IStoreState) => {
  const paymentInfo = getPaymentInfo(state);
  // invoices are added to list only after successful payment
  // so the only invoices that will have isPaymentProblem === true are the ones with chargebacks
  // all other payment problems happen during payment so there will be no invoice in the list
  // we can get the rest of payment problems from oneTimePurchases and subscriptions
  return state.paymentInfo.paymentProvider === 'stripe'
    ? [
        ...paymentInfo.invoices.filter((item) => item.isPaymentProblem && item.pdfUrl),
        ...paymentInfo.oneTimePurchases.filter(
          (item) =>
            item.isPaymentProblem &&
            item.renewUrl &&
            (item.type === 'failure_fee' ||
              !paymentInfo.invoices.find((i) => i.invoiceId === item.invoiceId))
        ),
        ...paymentInfo.subscriptions.filter(
          (item) =>
            item.isPaymentProblem &&
            item.receiptUrl &&
            !paymentInfo.invoices.find((i) => i.invoiceId === item.lastInvoiceId)
        )
      ]
    : [
        ...paymentInfo.subscriptions.filter((item) => item.isPaymentProblem && item.renewUrl),
        ...paymentInfo.oneTimePurchases.filter((item) => item.isPaymentProblem && item.renewUrl)
      ];
};

export const getIsBillingAddressFilled = createSelector(getPaymentInfo, ({ address, userId }) => {
  let isFilled = true;

  if (userId) {
    if (ifImplementsKeys(address, BILLING_ADD_FIELDS)) {
      Object.keys(address).forEach((key) => {
        if (
          !address[key as keyof IAdressInterface] ||
          address[key as keyof IAdressInterface] === ''
        ) {
          isFilled = false;
        }
      });
    } else {
      return false;
    }
  }

  return isFilled;
});

export const getIsSepaPending = (state: IStoreState) =>
  getPaymentInfo(state).subscriptions.some((item) => item.isSepaPending) ||
  getPaymentInfo(state).oneTimePurchases.some((item) => item.isSepaPending);
